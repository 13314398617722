import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyCTm6HvTpPjaS_YM1cxjKu243XvvJWTTEU',
  authDomain: 'codex-amparito.firebaseapp.com',
  projectId: 'codex-amparito',
  storageBucket: 'codex-amparito.appspot.com',
  messagingSenderId: '416662727416',
  appId: '1:416662727416:web:35ecc8d8f06853be35290d',
  measurementId: 'G-TJX1E9Z5MF',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
